/*
 * @Author: 智客云网络科技
 * @Date: 2022-02-07 01:29:06
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-13 10:16:17
 * @Description: 请输入文件描述信息
 * @FilePath: \src\views\Customer\api.js
 */

import http from "@/network/network.js";

// 创建新客户
export const inputNewCustomer = (params) =>
  http.post("/v1/Customer/InputNewCustomer", params);
