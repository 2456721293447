<!--
 * @Author: 智客云网络科技
 * @Date: 2022-02-09 10:45:15
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-10 09:48:19
 * @Description: 组织选择器
 * @FilePath: \src\components\organizationPicker\index.vue
-->
<template>
  <div class="">
    <van-popup v-model:show="showPicker" round position="bottom">
      <van-picker :columns="columns" @cancel="onCancel" @confirm="onConfirm" />
    </van-popup>
  </div>
</template>

<script>
import { getKorIdList } from "./api";
import { getCurrentInstance, ref, onMounted, watchEffect } from "vue";
export default {
  // props 注册接受 自定义标签
  props: {
    show: Boolean,
    kor_id: String,
    kor_name: String,
    one_kor: Boolean,
  },

  // emits 注册 接受自定义标签 属性绑定的事件
  emits: ["update:show", "update:kor_id", "update:kor_name", "update:one_kor"],

  setup(props, context) {
    const internalInstance = getCurrentInstance();
    const utils = internalInstance.appContext.config.globalProperties.$utils;

    const showPicker = ref(false); //控制选择器是否打开
    const columns = ref([]); //列表
    const kor_id = ref(""); //选中的组织ID
    const one_kor = ref(false); //只有一个ID

    //监听显示
    watchEffect(() => {
      showPicker.value = props.show;
    });

    //监听显示2
    watchEffect(() => {
      if (showPicker.value === false) {
        context.emit("update:show", false);
      }
    });

    //监听ID
    watchEffect(() => {
      kor_id.value = props.kor_id;
    });

    //监听一个ID
    watchEffect(() => {
      if (one_kor.value === true) {
        context.emit("update:one_kor", true);
      }
    });

    //触发确认选择
    const onConfirm = (val) => {
      context.emit("update:kor_id", val.value);
      context.emit("update:kor_name", val.text);
      context.emit("update:show", false);
      showPicker.value = false;
    };

    //触发取消选择
    const onCancel = () => {
      context.emit("update:show", false);
      showPicker.value = false;
    };

    //加载列表
    const loadList = () => {
      getKorIdList()
        .then((res) => {
          console.log("获取到" + res.data.length + "个组织");
          for (let i = 0; i < res.data.length; i++) {
            const item = res.data[i];
            columns.value.push({
              text: item.kor_name,
              value: item.korganization_id,
            });
          }

          if (res.data.length === 1) {
            one_kor.value = true;
          }

          onConfirm(columns.value[0]);
        })
        .catch((err) => {
          utils.fail(err.err_msg, 1000);
        });
    };

    onMounted(() => {
      loadList();
    });

    return {
      showPicker,
      onCancel,
      onConfirm,
      columns,
    };
  },
};
</script>
