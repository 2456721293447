<!--
 * @Author: 智客云网络科技
 * @Date: 2022-02-12 17:15:04
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-13 10:15:51
 * @Description: 添加新客户
 * @FilePath: \src\views\Customer\InputNewCustomer.vue
-->

<template>
  <div class="kc-app">
    <van-nav-bar
      title="新客户录入"
      left-arrow
      :fixed="true"
      :placeholder="true"
      :z-index="500"
      :safe-area-inset-top="true"
      @click-left="$router.go(-1)"
    />

    <!-- 组织筛选 -->
    <van-field
      v-model="kor_name"
      v-if="!one_kor"
      is-link
      readonly
      label="组织"
      placeholder="选择组织"
      @click="showKorPicker = true"
    />

    <!-- 客户姓名 -->
    <van-field
      v-model="name"
      label="客户姓名"
      autocomplete="off"
      placeholder=""
    />

    <!-- 手机号码 -->
    <van-field
      v-model="phone"
      label="手机号码"
      autocomplete="off"
      placeholder=""
    />

    <!-- 邮箱 -->
    <van-field v-model="email" label="邮箱" autocomplete="off" placeholder="" />

    <van-divider></van-divider>

    <div class="btn-box">
      <van-button
        style="width: 30%; margin-right: 15px"
        round
        size="small"
        icon="exchange"
        type="success"
        @click="addNewCustomer"
      >
        确认
      </van-button>
      <van-button
        style="width: 30%"
        round
        size="small"
        icon="replay"
        type="default"
        @click="initData"
      >
        重置
      </van-button>
    </div>

    <!-- 组织选择器 -->
    <organization-picker
      v-model:show="showKorPicker"
      v-model:kor_id="kor_id"
      v-model:kor_name="kor_name"
      v-model:one_kor="one_kor"
    ></organization-picker>
  </div>
</template>
<script>
import { getCurrentInstance, ref } from "vue";
import organizationPicker from "@/components/organizationPicker";
import { inputNewCustomer } from "./api";
export default {
  components: {
    organizationPicker,
  },
  setup() {
    const internalInstance = getCurrentInstance();
    const utils = internalInstance.appContext.config.globalProperties.$utils;

    const one_kor = ref(false); //是否只有一个组织
    const showKorPicker = ref(false); //控制组织选择器是否显示
    const kor_id = ref(""); //组织ID
    const kor_name = ref(""); //组织名称

    const name = ref(""); //客户姓名
    const phone = ref(""); //手机号码
    const email = ref(""); //邮箱

    //添加新用户
    const addNewCustomer = () => {
      if (utils.isNull(name.value)) {
        utils.msg("请输入客户姓名！");
        return;
      }

      inputNewCustomer({
        kor_id: kor_id.value,
        name: name.value,
        phone: phone.value,
        email: email.value,
      })
        .then((res) => {
          utils.success(res.err_msg);
          initData();
        })
        .catch((err) => {
          utils.fail(err.err_msg);
        });
    };

    //初始化
    const initData = () => {
      name.value = ""; //
      phone.value = ""; //
      email.value = ""; //
    };

    return {
      one_kor,
      showKorPicker,
      kor_id,
      kor_name,
      name,
      phone,
      email,
      addNewCustomer,
      initData,
    };
  },
};
</script>
